import React from "react";
import { Helmet } from "react-helmet";

import '../styles/legal/index.scss'

class Legal extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Mentions Legales | Menuiserie Perret</title>
          <meta name="description" type="description" content="Mentions Legales du site vitrine de la menuiserie perret" />
        </Helmet>
        <section className="legalHeader">
          <div className="content">
            {" "}
            <h1 className="secondary-title diff">
                Mentions légales
            </h1>
            <p className="diff">
                Les mentions légales obligatoires concernant  nom_du_site sont indiquées sur cette page conformément à la loi pour la confiance dans l'économie numérique (LCEN) de juin 2004.
            </p>
          </div>
          <div className="panelHeader"></div>
        </section>
        <section className="legalContent">
            <div className='legalContent__section'>
                <h2 className="tertiary-title">Identification de l'éditeur</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a massa id est dapibus consectetur. Sed tempus maximus turpis. Aenean euismod sem eu nisl volutpat, nec placerat lorem bibendum. Donec mattis sit amet diam nec iaculis. Pellentesque ut consequat neque. Phasellus sed elit ipsum. In nec porttitor massa, nec ullamcorper nibh. Aenean non elementum tellus, quis feugiat turpis. Morbi semper risus a ullamcorper imperdiet. Nunc eros est, finibus vitae convallis laoreet, faucibus viverra odio. Quisque laoreet imperdiet semper. Vestibulum a eros efficitur, dignissim tellus ut, commodo leo.</p>
            </div>
            <div className='legalContent__section'>
                <h2 className="tertiary-title">Directeur de la publication</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a massa id est dapibus consectetur. Sed tempus maximus turpis. Aenean euismod sem eu nisl volutpat, nec placerat lorem bibendum. Donec mattis sit amet diam nec iaculis. Pellentesque ut consequat neque. Phasellus sed elit ipsum. In nec porttitor massa, nec ullamcorper nibh. Aenean non elementum tellus, quis feugiat turpis. Morbi semper risus a ullamcorper imperdiet. Nunc eros est, finibus vitae convallis laoreet, faucibus viverra odio. Quisque laoreet imperdiet semper. Vestibulum a eros efficitur, dignissim tellus ut, commodo leo.</p>
            </div>
            <div className='legalContent__section'>
                <h2 className="tertiary-title">Prestataire d'hébergement</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a massa id est dapibus consectetur. Sed tempus maximus turpis. Aenean euismod sem eu nisl volutpat, nec placerat lorem bibendum. Donec mattis sit amet diam nec iaculis. Pellentesque ut consequat neque. Phasellus sed elit ipsum. In nec porttitor massa, nec ullamcorper nibh. Aenean non elementum tellus, quis feugiat turpis. Morbi semper risus a ullamcorper imperdiet. Nunc eros est, finibus vitae convallis laoreet, faucibus viverra odio. Quisque laoreet imperdiet semper. Vestibulum a eros efficitur, dignissim tellus ut, commodo leo.</p>
            </div>
            <div className='legalContent__section'>
                <h2 className="tertiary-title">Traitement des données à caractère personnel</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a massa id est dapibus consectetur. Sed tempus maximus turpis. Aenean euismod sem eu nisl volutpat, nec placerat lorem bibendum. Donec mattis sit amet diam nec iaculis. Pellentesque ut consequat neque. Phasellus sed elit ipsum. In nec porttitor massa, nec ullamcorper nibh. Aenean non elementum tellus, quis feugiat turpis. Morbi semper risus a ullamcorper imperdiet. Nunc eros est, finibus vitae convallis laoreet, faucibus viverra odio. Quisque laoreet imperdiet semper. Vestibulum a eros efficitur, dignissim tellus ut, commodo leo.</p>
            </div>
        </section>
      </>
    );
  }
}

export default Legal;